export const translations = {
    en: {
        header: {
            wallet: {
                wallet__title: "Wallet",
                wallet__copy: "Copy address",
                wallet__disconnect: "Disconnect"
            },
            profit__time: "Income in 5 min",
            total__profit: "Total income",
            current__profit: "Current income",
            claim: "Claim"
        },
        bonus: {
            title: "Daily Bonus",
            span: "Get from 1 to 100 likes every day!",
            time: "Time until the next bonus:",
            btn: "Claim Bonus",
            modal: {
                title: {
                    subscribe: "Subscribe to the channel",
                },
                text: {
                    subscribe: "To receive the bonus, you must subscribe to our channel.",
                    error: "An error occurred while retrieving the bonus.",
                    not_now: "You can't get the bonus yet."
                }
            },
        },
        task:{
            title: "Active Tasks",
            span: "Complete tasks and earn likes!",
            btn: `Add Advertisement`,
            modal: {
                title: {
                    invite__task: "Invite a friend",
                    transaction__completed: "Transaction Completed",
                },
                text: {
                    load__task__error: "Failed to load the task list.",
                    task__processed: "The task is already being processed, please wait.",
                    failed__complete: "Failed to complete the task.",
                    failed__userid: "Failed to retrieve user ID.",
                    invite__task: "Copy and send the link to a friend to earn a reward",
                    transaction__completed: "Payment completed successfully!",
                    transaction__failed: "Failed to process the payment. Please try again.",
                }
            }
        },
        referal: {
            title: "Referral System",
            level: {
                1: "Level 1",
                2: "Level 2",
                3: "Level 3",
                4: "Level 4",
                5: "Level 5",
            },
            invite: "Invite",
            modal: {
                title: {
                    get__ref: "Received from Friends",
                    
                },
                text: {
            
                }
            },
            no__data: "No data"
        },
        shop:{
            shop__btn:{
                upgrade: "Upgrade Channel",
                exchange: "Exchange",
                buy: "Buy Likes",
                withdrawal: "Withdrawal",
            },
            modal: {
                title: {
                    buy__likes__error: "Purchase Process",
                },
                text: {
                    load__exchange__data__error: "An error occurred while loading exchange data.",
                    load__boost__data__error: "Failed to retrieve the list of products for channel boosting.",
                    load__likes__data__error: "Failed to retrieve the list of products for purchasing likes.",
                    load__exchange__data__errors: "Failed to get exchange data.",
                    buy__boost__error: "Failed to purchase the product.",
                    buy__likes__error: "Purchasing likes for stars will soon be available and added to the game.",
                    exchange__error: "Exchange failed.",
                    buy__boost__success: "You have successfully boosted your channel.",
                    exchange__success: "Exchange was successful!",
                    no__money: "insufficient funds",
                    withdraw__error: "Failed to withdraw funds",
                    withdraw__success: "You have successfully withdrawn",
                    exchange__valid__amount: "Enter a valid amount for exchange.",
                }
            },
            shop__modal:{
                upgrade__channel: {
                    title: "Boost Channel",
                    span: "Income for 5 minutes",
                },
                exchange: {
                    title: "Exchange",
                    u__pay: "You pay",
                    btn: "Exchange",
                    rate: "Rate",
                    in: "in"

                },
                withdrawal: {
                    title: "Withdraw Money",
                    w__amount: "Withdrawal Amount",
                    balance: "Balance",
                    wallet: "Wallet Address",
                    connected: "Choose wallet or type manually",
                    incorrect: "The wallet address is incomplete or incorrect",
                    withdraw: "Withdraw",
                    history: "History",
                    history__created: "Created",
                    history__confirmed: "Confirmed",
                    history__nofound: "No history found."
                },
                likes: {
                    title: "Buy Likes"
                },
                both: "Shop is empty",
            }
        },
        ranking: {
            top__title: "Top 100 Players",
            top__span: "Displaying users with the highest profit in 5 minutes",
            bottom__title: "Rank",
            bottom__span: "Coins in 5 Minutes",
        },
        main: {
            modal: {
                title: {
                    error: "Error",
                    get__views__error: "Getting Views",
                    get__views__success: "Views Received",
                },
                text: {
                    get__views__error: "It\'s too early to take $MTUBE",
                    get__views__success: "You have received",
                    get__views__unexpected: "Failed to receive",
                }
            }, 
        },
        modal: {
            title: {
                error: "Error",
                success: "Success",
                warning: "Warning"
            },
            text: {
                copy__link: "The link has been copied to the clipboard",
                copy__link__error: "Failed to copy link to clipboard",
                wallet__connect: "Please connect your wallet before starting the task.",
                server__no__data: "The server did not return data. Please try again later."
            }
        },
        btn:{
            btn__copy: "Copy",
            btn__processed: "Proceed",
            btn__ok: "Ok"
        }
    },
    ru: {
        header: {
            wallet: {
                wallet__title: "Кошелек",
                wallet__copy: "Скопировать",
                wallet__disconnect: "Отключить"
            },
            profit__time: "Доход за 5 мин",
            total__profit: "Доход за все время",
            current__profit: "Текущий доход",
            claim: "Собрать"
        },
        bonus: {
            title: "Ежедневный бонус",
            span: "Получай от 1 до 100 лайков каждый день!",
            time: "Время до следующего бонуса:",
            btn: "Получить бонус",
            modal: {
                title: {
                    subscribe: "Подпишитесь на канал",
                },
                text: {
                    subscribe: "Чтобы получить бонус, вам необходимо подписаться на наш канал.",
                    error: "Произошла ошибка при получении бонуса.",
                    not_now: "Вы пока не можете получить бонус"
                }
            },
        },
        task:{
            title: "Активные задания",
            span: "Выполняйте задания и получайте лайки!",
            btn: `Добавить рекламу`,
            modal: {
                title: {
                    invite__task: "Пригласи друга",
                    transaction__completed: "Транзакция завершена",
                },
                text: {
                    load__task__error: "Не удалось загрузить список заданий.",
                    task__processed: "Задание уже обрабатывается, пожалуйста, подождите.",
                    failed__complete: "Не удалось выполнить задание.",
                    failed__userid: "Не удалось получить идентификатор пользователя.",
                    invite__task: "Скопируйте и отправьте ссылку другу, чтобы получить вознаграждение.",
                    transaction__completed: "Оплата успешно завершена!",
                    transaction__failed: "Не удалось обработать платеж. Попробуйте еще раз.",
                }
            }
        },
        referal: {
            title: "Реферальная система",
            level: {
                1: "Уровень 1",
                2: "Уровень 2",
                3: "Уровень 3",
                4: "Уровень 4",
                5: "Уровень 5",
            },
            invite: "Пригласить",
            modal: {
                title: {
                    get__ref: "Получено от друзей"
                },
                text: {
            
                }
            },
            no__data: "Нет данных"
        },
        shop:{
            shop__btn:{
                upgrade: "Прокачать канал",
                exchange: "Обменник",
                buy: "Купить лайки",
                withdrawal: "Вывести деньги",
            },
            modal: {
                title: {
                    buy__likes__error: "Процесс Покупки",
                },
                text: {
                    load__exchange__data__error: "Произошла ошибка при загрузке данных обмена.",
                    load__boost__data__error: "Не удалось получить список продуктов для прокачки канала.",
                    load__likes__data__error: "Не удалось получить список продуктов для покупки лайков.",
                    load__exchange__data__errors: "Не удалось получить данные обмена.",
                    buy__boost__error: "Не удалось приобрести товар.",
                    buy__likes__error: "Покупка лайков за звезды скоро будет доступна и добавлена в игру.",
                    exchange__error: "Произошла ошибка при обмене.",
                    buy__boost__success: "Вы успешно прокачали свой канал.",
                    exchange__success: "Обмен прошел успешно!",
                    no__money: "Недостаточно средств на вашем счету.",
                    withdraw__error: "Не удалось вывести средства.",
                    withdraw__success: "Вы успешно подали заявку на вывод ",
                    exchange__valid__amount: "Введите допустимую сумму для обмена.",
                }
            },
            shop__modal:{
                upgrade__channel: {
                    title: "Прокачка канала",
                    span: "Доход за 5 минут",
                },
                exchange: {
                    title: "Обменник",
                    u__pay: "Ты платишь",
                    btn: "Обменять",
                    rate: "Курс",
                    in: "в"

                },
                withdrawal: {
                    title: "Вывод средств",
                    w__amount: "Сумма вывода",
                    balance: "Баланс",
                    wallet: "Адрес кошелька",
                    connected: "Укажите адрес кошелька",
                    incorrect: "Адрес кошелька указан не полностью или неверно",
                    withdraw: "Вывести",
                    history: "История операций",
                    history__created: "Создано",
                    history__confirmed: "Подтверждено",
                    history__nofound: "История не найдена."
                },
                likes: {
                    title: "Покупка лайков"
                },
                both: "Магазин пуст",
            }
        },
        ranking: {
            top__title: "Топ 100 игроков",
            top__span: "Отображение пользователей с наибольшей прибылью за 5 минут",
            bottom__title: "Место",
            bottom__span: "Монеты за 5 минут",
        },
        main: {
            modal: {
                title: {
                    error: "Ошибка",
                    get__views__error: "Просмотры",
                    get__views__success: "Получены просмотры",
                },
                text: {
                    get__views__error: "Еще рано получать $MTUBE",
                    get__views__success: "Вы получили",
                    get__views__unexpected: "Не удалось получить",
                }
            }, 
        },
        modal: {
            title: {
                error: "Ошибка",
                success: "Успешно",
                warning: "Предупреждение"
            },
            text: {
                copy__link: "Ссылка скопирована в буфер обмена.",
                copy__link__error: "Не удалось скопировать ссылку в буфер обмена.",
                wallet__connect: "Пожалуйста, подключите свой кошелек перед началом выполнения.",
                server__no__data: "Сервер не вернул данные. Повторите попытку позже."
            }
        },
        btn:{
            btn__copy: "Скопировать",
            btn__processed: "Выполнить",
            btn__ok: "Ок"
        }
    },
};
