import { config } from './../../config';
import $ from 'jquery';
import Modal from '../main.js'
import Animation from '../components/animations.js';

/**
 * sendPostRequest('/api/data', { key: 'value' }, { 'Authorization': 'Bearer your-token-here' })
 *     .then(data => console.log('Success:', data));
 *
 * @param url
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
export function sendPostRequest(url, data, headers = {}) {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
            ...headers
        },
        mode: 'cors',
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(error => console.error('Error:', error));
}


/**
 * sendGetRequest('/api/data', { 'Authorization': 'Bearer your-token-here' })
 *     .then(data => console.log('Success:', data));
 *
 * @param url
 * @param headers
 * @returns {Promise<any>}
 */
export function sendGetRequest(url, headers = {}) {
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0',
            ...headers
        },
        mode: 'cors',
    })
        .then(response => response.json())
        .catch(error => console.error('Error:', error));
}

export function saveToLocalStorage(key, value) {
    if (isLocalStorageAvailable()) {
        const data = typeof value === 'object' ? JSON.stringify(value) : value;
        localStorage.setItem(key, data);
    } else {
        saveToIndexedDB(key, value);
    }
}

export function getFromLocalStorage(key) {
    if (isLocalStorageAvailable()) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    } else {
        return getFromIndexedDB(key);
    }
}

// Проверка доступности localStorage
export function isLocalStorageAvailable() {
    try {
        const testKey = '__storage_test__';
        localStorage.setItem(testKey, 'test');
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
}

// Функции для работы с IndexedDB
function saveToIndexedDB(key, value) {
    const request = indexedDB.open('myDatabase', 1);

    request.onupgradeneeded = function() {
        const db = request.result;
        if (!db.objectStoreNames.contains('store')) {
            db.createObjectStore('store', { keyPath: 'key' });
        }
    };

    request.onsuccess = function() {
        const db = request.result;
        const transaction = db.transaction('store', 'readwrite');
        const store = transaction.objectStore('store');
        store.put({ key, value });
    };

    request.onerror = function() {
        console.error('Ошибка при сохранении данных в IndexedDB');
    };
}

function getFromIndexedDB(key) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('myDatabase', 1);

        request.onsuccess = function() {
            const db = request.result;
            const transaction = db.transaction('store', 'readonly');
            const store = transaction.objectStore('store');
            const getRequest = store.get(key);

            getRequest.onsuccess = function() {
                resolve(getRequest.result ? getRequest.result.value : null);
            };

            getRequest.onerror = function() {
                reject('Ошибка при получении данных из IndexedDB');
            };
        };

        request.onerror = function() {
            reject('Ошибка при подключении к IndexedDB');
        };
    });
}


export function removeFromLocalStorage(key) {
    localStorage.removeItem(key);
}

/**
 * Показ модального окна через JS с заголовком и текстом.
 * @param title
 * @param body
 */
export function showModal(title, body, isSuccess = false, referralLink = '') {
    const modalContainer = document.createElement('div');
    modalContainer.classList.add('modal', 'modal--active');
    modalContainer.setAttribute('data-modal-name', 'custom-modal');
    document.body.classList.add('no-scroll');
    var animation = new Animation();

    const modalImage = isSuccess ? 'img/modal/success.png' : 'img/modal/error.png';

    let copyButtonHTML = '';
    let okButtonHTML = '';

    if (referralLink) {
        copyButtonHTML = `<button class="btn primary--btn" id="copy-referral-link">Скопировать ссылку</button>`;
    } else {
        okButtonHTML = `<button class="btn primary--btn" data-modal-close>OK</button>`;
    }

    modalContainer.innerHTML = `
        <div class="modal__content">
            <span class="modal__close" data-modal-close><img src="img/svg/close.svg" alt=""></span>
            <div class="modal__image"><img src="${modalImage}" alt=""></div>
            <p class="modal__title">${title}</p>
            <span class="modal__body">${body}</span>
            ${copyButtonHTML}
            ${okButtonHTML}
        </div>
    `;

    animation.addAnimation(modalContainer, {
        animationName: ['opacityIn'],
        duration: ['0.3'],
        delay: ['0'],
        timingFunction: ['linear']
    }); 

    document.body.appendChild(modalContainer);

    if (referralLink) {
        const copyButton = modalContainer.querySelector('#copy-referral-link');
        copyButton.addEventListener('click', () => {
            navigator.clipboard.writeText(referralLink).then(() => {
                /* showModal('Успех', 'Ссылка успешно скопирована!', true); */
            }).catch(err => {
                showModal('Ошибка', 'Не удалось скопировать ссылку.', true);
            });
        });
    }

    modalContainer.querySelectorAll('[data-modal-close]').forEach(btn => {
        btn.addEventListener('click', () => {
            closeModal(modalContainer);
        });
    });

    function closeModal(modal) {
        modal.classList.remove('modal--active');
        document.body.classList.remove('no-scroll');
        setTimeout(() => modal.remove(), 300);
    }

    return closeModal;
}

export function auth(telegramUser) {
    return sendPostRequest(config.BASE_URL + '/auth/login', {
        query_id: telegramUser.query_id,
        user: telegramUser.user,
        auth_date: parseInt(telegramUser.auth_date),
        hash: telegramUser.hash
    })
    .then(response => {
        if (response.data.error) {
            showModal('Ошибка', 'Ваш аккаунт заблокирован!');
            return;
        }
        saveToLocalStorage('token', response.data.token);
        saveToLocalStorage('last_bonus_date', response.data.last_bonus_date);
        saveToLocalStorage('balance', response.data.balance);
        updateUserStat(response.data);
    })
    .catch(error => {
        console.error('Ошибка при авторизации:', error);
    });
}


export function getUserStat() {

    sendGetRequest(config.BASE_URL + '/user/stat', {
        "Authorization": "Bearer " + getFromLocalStorage('token')
    }).then(response => {
        var data = response.data;
        window.addEventListener('DOMContentLoaded', () => {
            const avatarImg = document.querySelector('.profile__info .avatar img');
            if (avatarImg) {
                avatarImg.src = config.ASSET_URL + data.avatar;
            }

            const rankingAvatar = document.querySelector('.ranking__top .profile__info .avatar img');
            if (rankingAvatar) {
                rankingAvatar.src = config.ASSET_URL + data.avatar;
            }
        });

        const telegramUser = window.telegramUser;
        document.querySelector('.profile__header .profile__info .nickname').textContent = telegramUser.first_name;

        document.querySelector('.profile__header .profile__data--item.like p').textContent = data.likes;
        document.querySelector('.profile__header .profile__data--item.ton p').textContent = data.balance;
        document.querySelector('.profile__header .profile__data--item.mtube p').textContent = "+ " + data.views_per_period;
        document.querySelector('.page[page="main"] .score p').textContent = data.views;

        // ranking
        document.querySelector('.ranking__top .score p').textContent = data.views_per_period;
        document.querySelector('.ranking__top .nickname').textContent = telegramUser.first_name;

        updateUserStat(response.data);
    });
}

function updateUserStat(data) {
    saveToLocalStorage('avatar', config.ASSET_URL + data.avatar);
    saveToLocalStorage('last_claim', data.last_claim_date);
    updateStatValue('last_bonus_date', data.last_bonus_date);
    updateStatValue('user_name', getFromLocalStorage('first_name') + ' ' + getFromLocalStorage('last_name'));
    updateStatValue('views_count', data.views);
    updateStatValue('likes_count', data.likes);
    updateStatValue('balance_count', data.balance);
    updateStatValue('views_per_period', "+ " + data.views_per_period);
    updateStatValue('subscribers_count', data.subscribers);
    updateStatValue('date_last_bonus', data.date_last_bonus);
    updateStatValue('wallet_address', data.wallet_address);
}

export function updateStatValue(id, value) {
    const element = document.getElementById(id);
    if (element) {
        element.textContent = value;
    }
}