import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
    const withdrawal__form = document.querySelector('.withdrawal__form');
    const withdrawal__inputs = withdrawal__form.querySelectorAll('input');
    const exchange__form = document.querySelector('.exchange__form');
    const exchange__inputs = exchange__form.querySelectorAll('input');
    const wallet__form = document.querySelector('.wallet__form');
    const wallet__inputs = wallet__form.querySelector('input');

    withdrawal__inputs.forEach(input => {
        input.addEventListener('focus', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').addClass('exchange__form--shifted');
            }
        });

        input.addEventListener('blur', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').removeClass('exchange__form--shifted');
            }
        });
    });
    exchange__inputs.forEach(input => {
        input.addEventListener('focus', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').addClass('exchange__form--shifted');
            }
        });

        input.addEventListener('blur', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').removeClass('exchange__form--shifted');
            }
        });
    });

    wallet__inputs.forEach(input => {
        input.addEventListener('focus', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').addClass('exchange__form--shifted');
            }
        });

        input.addEventListener('blur', () => {
            if (window.innerWidth <= 768) {
                $('.modal__content').removeClass('exchange__form--shifted');
            }
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const addressInput = document.querySelector('.input__item.adress input');
    const clearIcon = document.querySelector('.clear-icon');
    const errorMessage = document.querySelector('.error-message');

    addressInput.addEventListener('input', () => {
        if (addressInput.value.length > 0) {
            clearIcon.style.display = 'block';
        } else {
            clearIcon.style.display = 'none';
        }
    });

    clearIcon.addEventListener('click', () => {
        addressInput.value = '';
        clearIcon.style.display = 'none';
    });

    addressInput.addEventListener('blur', () => {
        if (addressInput.value.length < 64) {
            addressInput.classList.add('error');
            errorMessage.style.display = 'block';
        } else {
            addressInput.classList.remove('error');
            errorMessage.style.display = 'none';
        }
    });
});

const tonInput = document.querySelector('.input__item input[type="number"]');
const minWarning = document.querySelector('.min');

tonInput.addEventListener('input', () => {
    const value = parseFloat(tonInput.value);
    if (!isNaN(value) && value < 0.2) {
        tonInput.classList.add('error');
        minWarning.classList.add('error');
    } else {
        tonInput.classList.remove('error');
        minWarning.classList.remove('error');
    }
});

document.addEventListener('DOMContentLoaded', function () {
    const amountInput = document.getElementById('amount-input');
    const walletInput = document.getElementById('wallet-input');
    const withdrawButton = document.getElementById('withdraw-btn');
    const minWarning = document.getElementById('min-warning');
    const walletError = document.getElementById('wallet-error');
    
    const MIN_AMOUNT = 0.2;
    const VALID_WALLET_REGEX = /^[A-Za-z0-9\-]{20,100}$/;

    // Функция для проверки валидности формы
    function validateForm() {
        let isValid = true;

        // Проверка суммы
        const amount = parseFloat(amountInput.value);
        if (isNaN(amount) || amount < MIN_AMOUNT) {
            minWarning.classList.add('error');
            isValid = false;
        } else {
            minWarning.classList.remove('error');
        }

        // Проверка адреса кошелька
        const walletAddress = walletInput.value.trim();
        if (!VALID_WALLET_REGEX.test(walletAddress)) {
            walletError.style.display = 'block';
            isValid = false;
        } else {
            walletError.style.display = 'none';
        }

        // Если все поля валидны, разблокируем кнопку
        withdrawButton.disabled = !isValid;
    }

    // Вешаем события для проверки на изменение значений в полях
    amountInput.addEventListener('input', validateForm);
    walletInput.addEventListener('input', validateForm);
});



document.addEventListener('DOMContentLoaded', () => {
    const addressInput = document.querySelector('.input__item.adress input');
    const clearIcon = document.querySelector('.clear-icon');
    const errorMessage = document.querySelector('.error-message');
    const errorIcon = document.querySelector('.error-icon');
    const VALID_WALLET_REGEX = /^[A-Za-z0-9\-]{20,100}$/;

    addressInput.addEventListener('input', () => {
        const walletAddress = addressInput.value.trim();

        // Проверяем корректность ввода адреса кошелька
        if (!VALID_WALLET_REGEX.test(walletAddress)) {
            addressInput.classList.add('error');
            errorIcon.style.display = 'block';
            errorMessage.style.display = 'block';
            clearIcon.style.display = 'none';
        } else {
            addressInput.classList.remove('error');
            errorIcon.style.display = 'none';
            errorMessage.style.display = 'none';
            clearIcon.style.display = 'block';
        }

        // Если поле пустое, убираем иконку очистки
        if (walletAddress.length === 0) {
            clearIcon.style.display = 'none';
        }
    });

    clearIcon.addEventListener('click', () => {
        addressInput.value = '';
        clearIcon.style.display = 'none';
        errorIcon.style.display = 'none';
        errorMessage.style.display = 'none';
        addressInput.classList.remove('error');
    });

    addressInput.addEventListener('blur', () => {
        const walletAddress = addressInput.value.trim();

        // Если адрес невалиден при потере фокуса, показываем ошибки
        if (!VALID_WALLET_REGEX.test(walletAddress)) {
            addressInput.classList.add('error');
            errorIcon.style.display = 'block';
            errorMessage.style.display = 'block';
            clearIcon.style.display = 'none';
        } else {
            addressInput.classList.remove('error');
            errorIcon.style.display = 'none';
            errorMessage.style.display = 'none';
        }
    });
});

