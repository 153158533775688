import { TonConnectUI } from '@tonconnect/ui';
import { UserRejectsError } from "@tonconnect/sdk";
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat, saveToLocalStorage } from '../functions/api';
import { config } from './../../config';

let tonConnectUIInstance = null;

class TonWallet {
    constructor() {
        try {
            if (!tonConnectUIInstance) {
                tonConnectUIInstance = new TonConnectUI({
                    manifestUrl: 'https://testmt.xyz/assets/tonconnect-manifest.json'
                });
            }
            this.tonConnectUI = tonConnectUIInstance;
        } catch (error) {
            console.error("Ошибка при инициализации TonConnectUI:", error);
        }
    }

    async connect() {
        try {
            if (this.tonConnectUI.wallet) {
                console.log("Кошелек уже подключен:", this.tonConnectUI.wallet);
                return this.tonConnectUI.wallet;
            } else {
                await this.tonConnectUI.connectWallet();
                console.log("Кошелек успешно подключен:", this.tonConnectUI.wallet);
                saveToLocalStorage('wallet_adress', walletInfo.account.address)
                const walletInfo = this.tonConnectUI.getWalletInfo();
                const cleanAddress = walletInfo.account.address.startsWith('0:') ? address.replace(/^0:/, '') : address;
                const base64Address = this.tonHexToBase64(walletInfo.account.address);
                const shortAddress = `${base64Address.slice(0, 4)}...${base64Address.slice(-4)}`;
                if (this.walletText) {
                    this.walletText.textContent = shortAddress;
                }
                return this.tonConnectUI.wallet;
            }
        } catch (error) {
            console.error("Ошибка подключения к кошельку:", error);
            throw error;
        }
    }

    async disconnect() {
        try {
            await this.tonConnectUI.disconnect();
            console.log("Кошелек отключен");
        } catch (error) {
            console.error("Ошибка при отключении кошелька:", error);
        }
    }

    getWalletInfo() {
        return this.tonConnectUI.wallet;
    }

    async sendTonPayment(amount, toAddress) {
        let transactionResult;
        try {
            const convertedAmount = this.convertAmount(amount);

            const txData = {
                validUntil: Math.round(Date.now() / 1000) + 600,
                messages: [{ address: toAddress, amount: convertedAmount.toString() }]
            };

            transactionResult = await this.tonConnectUI.sendTransaction(txData);
            console.log("Транзакция успешно выполнена:", transactionResult);

            return { status: 'success', data: transactionResult };
        } catch (error) {
            console.error("Ошибка транзакции:", error);

            if (error instanceof UserRejectsError) {
                console.error("Пользователь отклонил запрос.");
                return { status: 'false', error: 'Пользователь отклонил запрос.' };
            }

            if (error.message.includes('insufficient funds')) {
                console.error("Недостаточно средств на кошельке.");
                return { status: 'false', error: 'Недостаточно средств.' };
            }

            return { status: 'false', error: error.message };
        } finally {
            await this.sendTransactionDataToServer(transactionResult);
        }
    }

    convertAmount(nanoTonValue) {
        return parseFloat(nanoTonValue) * 1000000000;
    }

    async sendTransactionDataToServer(transactionResult) {
        try {
            const response = await sendPostRequest(config.BASE_URL + '/transaction/confirm', {
                transactionData: transactionResult
            }, {
                "Authorization": "Bearer " + getFromLocalStorage('token')
            });

            if (response.success) {
                console.log("Данные транзакции успешно отправлены на сервер.");
            } else {
                console.error("Ошибка при отправке данных на сервер:", response.message);
            }
        } catch (error) {
            console.error("Ошибка при отправке данных транзакции на сервер:", error);
        }
    }

    isConnected() {
        return this.connected;
    }

    tonHexToBase64(hexAddress) {
        if (!hexAddress.includes(":")) {
            throw new Error("Invalid TON address format: missing ':' separator");
        }
    
        const [workchainIdStr, addressHex] = hexAddress.split(":");
        const workchainId = parseInt(workchainIdStr, 10);
        const addressBytes = Uint8Array.from(
            addressHex.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
        );
    
        const packedAddress = new Uint8Array(1 + addressBytes.length);
        packedAddress[0] = workchainId;
        packedAddress.set(addressBytes, 1);
    
        const checksum = this.crc32(packedAddress);
        const fullAddress = new Uint8Array(packedAddress.length + 4);
        fullAddress.set(packedAddress);
        fullAddress.set(checksum, packedAddress.length);
    
        const binaryString = String.fromCharCode(...fullAddress);
    
        return btoa(binaryString)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }

    crc32(data) {
        let crc = 0xffffffff;
        for (let i = 0; i < data.length; i++) {
            let byte = data[i];
            crc = crc ^ byte;
            for (let j = 0; j < 8; j++) {
                const mask = -(crc & 1);
                crc = (crc >>> 1) ^ (0xedb88320 & mask);
            }
        }
        return new Uint8Array([
            (crc ^ 0xffffffff) >>> 24,
            ((crc ^ 0xffffffff) >>> 16) & 0xff,
            ((crc ^ 0xffffffff) >>> 8) & 0xff,
            (crc ^ 0xffffffff) & 0xff
        ]);
    }
}

export default TonWallet;
