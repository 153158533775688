import TonWallet from './tonconnect.js';
import { sendGetRequest, sendPostRequest, getFromLocalStorage, showModal, getUserStat, saveToLocalStorage } from '../functions/api';
import { config } from './../../config';
import Modal from './modal';

class Wallet {
    constructor() {
        this.walletButton = document.querySelector('.wallet__link');
        this.walletText = this.walletButton ? this.walletButton.querySelector('.wallet__link--text') : null;
        this.tonWallet = new TonWallet();
        this.isProcessing = false;

        const storedAddress = getFromLocalStorage('wallet_adress');
        if (!storedAddress) {
            this.updateWalletUI('0:Кошелек');
        } else {
            this.updateWalletUI(storedAddress);
        }

        this.setupModal();
    }

    async initWallet() {
        try {
            const walletInfo = this.tonWallet.getWalletInfo();
            if (walletInfo && walletInfo.account) {
                const base64Address = this.tonHexToBase64(walletInfo.account.address);
                this.updateWalletUI(base64Address);
                saveToLocalStorage('wallet_adress', base64Address);
            } else {
                const connectedWallet = await this.tonWallet.connect();
                const base64Address = this.tonHexToBase64(connectedWallet.address);
                saveToLocalStorage('wallet_adress', base64Address);
                this.updateWalletUI(base64Address);
            }
        } catch (error) {
            console.error("Ошибка при подключении:", error);
        }
    }

    async setupModal() {
        if (this.walletButton) {
            this.walletButton.addEventListener('click', async () => {
                this.initWallet();
                const walletInfo = this.tonWallet.getWalletInfo();
                if (walletInfo && walletInfo.account) {
                    const base64Address = this.tonHexToBase64(walletInfo.account.address);
                    Modal.showWalletModal(base64Address, {
                        onCopy: () => this.copyAddress(base64Address),
                        onDisconnect: async () => {
                            await this.tonWallet.disconnect();
                            this.walletText.textContent = "Кошелек";
                        }
                    });
                } else {
                    const connectedWallet = await this.tonWallet.connect();
                    const base64Address = this.tonHexToBase64(connectedWallet.address);
                    this.updateWalletUI(base64Address);
                    Modal.showWalletModal(base64Address, {
                        onCopy: () => this.copyAddress(base64Address),
                        onDisconnect: async () => {
                            await this.tonWallet.disconnect();
                            this.walletText.textContent = "Кошелек";
                        }
                    });
                }
            });
        }
    }

    updateWalletUI(address) {
        if (!address) {
            console.error("Адрес не определен");
            return;
        }
        const shortAddress = `${address.slice(0, 4)}...${address.slice(-4)}`;
        if (this.walletText) {
            this.walletText.textContent = shortAddress;
        }
    }

    copyAddress(address) {
        navigator.clipboard.writeText(address).then(() => {
            alert("Адрес скопирован!");
        }).catch((err) => {
            console.error("Ошибка при копировании адреса:", err);
        });
    }

    tonHexToBase64(hexAddress) {
        if (!hexAddress.includes(":")) {
            throw new Error("Invalid TON address format: missing ':' separator");
        }
    
        const [workchainIdStr, addressHex] = hexAddress.split(":");
        const workchainId = parseInt(workchainIdStr, 10);
        const addressBytes = Uint8Array.from(
            addressHex.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
        );
    
        const packedAddress = new Uint8Array(1 + addressBytes.length);
        packedAddress[0] = workchainId;
        packedAddress.set(addressBytes, 1);
    
        const checksum = this.crc32(packedAddress);
        const fullAddress = new Uint8Array(packedAddress.length + 4);
        fullAddress.set(packedAddress);
        fullAddress.set(checksum, packedAddress.length);
    
        const binaryString = String.fromCharCode(...fullAddress);
    
        return btoa(binaryString)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }
    

    crc32(data) {
        let crc = 0xffffffff;
        for (let i = 0; i < data.length; i++) {
            let byte = data[i];
            crc = crc ^ byte;
            for (let j = 0; j < 8; j++) {
                const mask = -(crc & 1);
                crc = (crc >>> 1) ^ (0xedb88320 & mask);
            }
        }
        return new Uint8Array([
            (crc ^ 0xffffffff) >>> 24,
            ((crc ^ 0xffffffff) >>> 16) & 0xff,
            ((crc ^ 0xffffffff) >>> 8) & 0xff,
            (crc ^ 0xffffffff) & 0xff
        ]);
    }
}

export default Wallet;
