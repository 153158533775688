import Animation from './animations.js';

class Modal {
    constructor() {
        this.modals = document.querySelectorAll('.modal');
        this.bindButtons();
        this.animation = new Animation();
    }

    bindButtons() {
        document.querySelectorAll('[data-modal]').forEach(button => {
            const modalName = button.getAttribute('data-modal');
            button.addEventListener('click', () => {
                this.openModal(modalName);
            });
        });

        this.modals.forEach(modal => {
            const closeButtons = modal.querySelectorAll('[data-modal-close]');
            
            closeButtons.forEach(closeButton => {
                closeButton.addEventListener('click', () => {
                    this.closeModal(modal);
                });
            });

            modal.addEventListener('click', (event) => {
                if (event.target === modal) {
                    this.closeModal(modal);
                }
            });
        });
    }

    openModal(modalName) {
        const modal = document.querySelector(`.modal[data-modal-name="${modalName}"]`);
        if (modal) {
            modal.classList.add('modal--active');
            document.body.classList.add('no-scroll');
            this.animation.addAnimation(modal, {
                animationName: ['opacityIn'],
                duration: ['0.3'],
                delay: ['0'],
                timingFunction: ['linear']
            });
            this.animation.addAnimation('[data-modal-name="купить-лайки"] .modal__content', {
                animationName: ['fadeUp'],
                duration: ['0.4'],
                delay: ['0'],
                timingFunction: ['linear']
            });
        }
    }

    openWalletsModal(walletsList) {
        const walletsContainer = document.querySelector('.wallets-list');
        walletsContainer.innerHTML = walletsList.map(wallet => `
            <button class="wallet-item" data-wallet-id="${wallet.name}">
                ${wallet.name}
            </button>
        `).join('');

        this.openModal('wallets');
    }

    static showWalletModal(address, actions) {
        // Проверяем, есть ли уже открытое модальное окно
        const existingModal = document.querySelector('.modal.modal--active');
        if (existingModal) {
            // Если окно уже открыто, не создаем новое
            return;
        }

        const modalContainer = document.createElement('div');
        modalContainer.classList.add('modal', 'modal--active');
        modalContainer.setAttribute('data-modal-name', 'wallet-modal');
        document.body.classList.add('no-scroll');
    
        modalContainer.innerHTML = `
            <div class="modal__content">
                <span class="modal__close" data-modal-close><img src="img/svg/close.svg" alt=""></span>
                <p class="modal__title">Адрес кошелька</p>
                <p class="modal__body" style="word-break: break-all;">${address}</p>
                <div class="wallet__btn">
                    <button class="btn primary--btn wallet__disconnect-btn">Отключить кошелек</button>
                    <button class="btn primary--btn wallet__copy-btn"><img src="img/svg/referal/copy.svg" alt=""></button>
                </div>
            </div>
        `;
    
        document.body.appendChild(modalContainer);
    
        const modalInstance = new Modal();
    
        modalContainer.querySelector('.wallet__copy-btn').addEventListener('click', () => {
            actions.onCopy();
            modalInstance.closeModal(modalContainer);
        });
    
        modalContainer.querySelector('.wallet__disconnect-btn').addEventListener('click', () => {
            actions.onDisconnect();
            modalInstance.closeModal(modalContainer);
        });
    
        modalContainer.querySelector('[data-modal-close]').addEventListener('click', () => {
            modalInstance.closeModal(modalContainer);
        });
    }
    
    closeModal(modal) {
        this.animation.addAnimation(modal, {
            animationName: ['opacityOut'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        }); 
        this.animation.addAnimation('[data-modal-name="купить-лайки"] .modal__content', {
            animationName: ['fadeDown'],
            duration: ['0.3'],
            delay: ['0'],
            timingFunction: ['linear']
        });

        setTimeout(() => {
            modal.classList.remove('modal--active');
            document.body.classList.remove('no-scroll');
            modal.remove();  // Удаляем модальное окно из DOM
        }, 300);
    }

    closeModalByName(modalName) {
        const modal = document.querySelector(`.modal[data-modal-name="${modalName}"]`);
        if (modal) {
            this.closeModal(modal);
        }
    }
}


export default Modal;
