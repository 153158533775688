import $ from 'jquery';

import './_functions';
import { getTranslation } from './_functions';

// components

import Menu from './components/menu.js';
import Ranking from './components/ranking.js';
import Shop from './components/shop.js';
import ReferralSystem from './components/referralSystem.js';
import Task from './components/task.js';
import Animation from './components/animations.js';
import Bonus from './components/bonus.js';
import Wallet from './components/wallet.js';

// api

import { auth, getUserStat, getFromLocalStorage, sendGetRequest, showModal, parseTgWebAppData, saveToLocalStorage } from './functions/api';
import { config } from './../config';



class App {
    constructor() {
        this.animation = new Animation();
        this.currentLanguage = getFromLocalStorage('language') || 'en';
        console.log(this.currentLanguage)
        this.init();
    }

    async init() {
        try {
            // Авторизация
            await this.initAuth();

            // Инициализация меню
            new Menu('.bottom__menu__item', '.page', '.header');

            // Перевод
            this.applyTranslations();
            this.bindLanguageSwitch();
            this.updateLanguageSwitchButton();

            // Бонус
            const bonus = new Bonus();

            // Кошелек
            new Wallet();

            // Инициализация рейтинга
            this.initRanking();

            // Инициализация магазина
            this.initShop();

            // Инициализация реферальной системы
            this.initReferralSystem();

            // Обновление данных пользователя
            this.updateViewsCount();

            // Инициализация кнопки "Play"
            this.initPlayButton();

            // Инициализация заданий
            this.initTask();

        } catch (error) {
            console.error("Ошибка при инициализации приложения:", error);
            /* alert(error); */
        } finally {
            this.hidePreloader();
        }
    }

    async initAuth() {
        $('#preloader').show();
    
        const urlParams = new URLSearchParams(window.location.hash.substring(1));
        const tgWebAppData = urlParams.get('tgWebAppData');

        const telegramUser = tgWebAppData;

        console.log(urlParams)
        
        await auth(telegramUser);
    
        const startTime = Date.now();
        const totalDuration = 4500;
    
        const updateProgressBar = (elapsedTime) => {
            const progress = Math.min((elapsedTime / totalDuration) * 100, 100);
            $('.loading-bar').css('width', `${progress}%`);
        };
    
        const progressInterval = setInterval(() => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            updateProgressBar(elapsedTime);
    
            if (elapsedTime >= totalDuration) {
                clearInterval(progressInterval);
            }
        }, 100);
    
        try {
            const endTime = Date.now();
            const duration = endTime - startTime;
            const waitTime = Math.max(0, totalDuration - duration);
    
            await getUserStat();
        } catch (error) {
            console.error("Error during authentication:", error);
        } finally {
            clearInterval(progressInterval);
        }
    
        await this.fetchReferralInfo();
    }
    
    
    hidePreloader() {
        $('#preloader').fadeOut();
    }

    initTask() {
        const task = new Task('.tasks__list');
        task.init();
    }
    
    initRanking() {
        new Ranking('.ranking__btn', '.ranking__wrapper', '.btn__back', '.content');
    }    

    initShop() {
        new Shop();
    }

    initReferralSystem() {
        new ReferralSystem();
        this.bindClaimButton(); 
    }

    updateViewsCount() {
        const viewsCount = getFromLocalStorage('views_count');
        if (viewsCount) {
            $('.score p').text(viewsCount);
        }
    }

    applyTranslations() {
        const elements = document.querySelectorAll('[data-translate-key]');
        elements.forEach((el) => {
            const key = el.getAttribute('data-translate-key');
            const translation = getTranslation(this.currentLanguage, key);
            if (translation) {
                el.textContent = translation;
            }
        });
    }    

    bindLanguageSwitch() {
        const switchButton = document.querySelector('#language-switch');
        switchButton.addEventListener('click', () => {
            this.currentLanguage = this.currentLanguage === 'en' ? 'ru' : 'en';
            saveToLocalStorage('language', this.currentLanguage);
            this.updateLanguageSwitchButton();
            this.applyTranslations();
        });
    }

    updateLanguageSwitchButton() {
        const switchButton = document.querySelector('#language-switch');
        switchButton.textContent = this.currentLanguage.toUpperCase();
    }

    initPlayButton() {
        $('.play').on('click', () => {
            this.animation.addAnimation('.play', {
                animationName: ['zoomPlay'],
                duration: ['0.5'],
                delay: ['0'],
                timingFunction: ['linear']
            });      
            this.animation.addAnimation('.play img', {
                animationName: ['rotatePlay'],
                duration: ['0.5'],
                delay: ['0'],
                timingFunction: ['linear']
            });              
            this.claim();
        });
    }

    async fetchReferralInfo() {
        try {
            const response = await sendGetRequest(config.BASE_URL + '/referal/index', {
                Authorization: 'Bearer ' + getFromLocalStorage('token')
            });
            const data = response.data;
            $('#all-time-profit').text(data.total_profit);
            $('#now-profit').text(data.partner_balance);

            if(data.partner_balance <= 0){
                document.querySelector('.referal--claim').disabled = true;
            }

        } catch (error) {
            console.error("Error while retrieving referral information:", error);
        }
    }

    async claim() {
        const lastClaim = getFromLocalStorage('last_claim');

        if (lastClaim !== null && !this.canSendClaim(lastClaim)) {
            showModal(getTranslation(this.currentLanguage, 'main.modal.title.get__views'), getTranslation(this.currentLanguage, 'main.modal.text.get__views__error'), false);
            return false;
        }

        try {
            const response = await sendGetRequest(config.BASE_URL + '/user/claim', {
                Authorization: 'Bearer ' + getFromLocalStorage('token')
            });

            if (response && response.data) {
                showModal(getTranslation(this.currentLanguage, 'main.modal.title.get__views__success'), getTranslation(this.currentLanguage, 'main.modal.text.get__views__success') + ' <span class="text mtube">' + response.data.claimed + ' $MTUBE</span>', true);
                getUserStat();
            } else {
                showModal(getTranslation(this.currentLanguage, 'main.modal.title.error'), getTranslation(this.currentLanguage, 'main.modal.text.get__views__unexpected') +  ' <span class="text mtube">$MTUBE</span>', false);
            }
        } catch (error) {
            showModal(getTranslation(this.currentLanguage, 'main.modal.title.error'), getTranslation(this.currentLanguage, 'main.modal.text.get__views__unexpected') +  ' <span class="text mtube">$MTUBE</span>.', false);
        }
    }

    refreshReferralPage() {
        sendGetRequest(config.BASE_URL + '/referal/index', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            this.updateReferralStats(response.data);
        });
    }

    updateReferralStats(data) {
        $('#all-time-profit').text(data.total_profit);
        $('#now-profit').text(data.partner_balance);
        
        if (data.partner_balance > 0) {
            document.querySelector('.referal--claim').disabled = false;
        } else {
            document.querySelector('.referal--claim').disabled = true;
        }
    }

    bindClaimButton() {
        $('.referal--claim').on('click', () => {
            this.claimReferralProfit();
        });
    }

    claimReferralProfit() {
        sendGetRequest(config.BASE_URL + '/referal/claim', {
            "Authorization": "Bearer " + getFromLocalStorage('token')
        }).then(response => {
            if (response.success === false) {
                showModal('Error', response.data.message);
                return;
            }

            showModal(getTranslation(this.currentLanguage,'referal.modal.title.get__ref'), response.data.amount + '<img src="img/svg/currency/like.svg" alt="">', true);
            this.refreshReferralPage();
            getUserStat();
        });
    }

    canSendClaim(lastClaimDate) {
        lastClaimDate = lastClaimDate.replace(' ', 'T') + 'Z';
        const currentTime = new Date(Date.now()).getTime();
        lastClaimDate = new Date(lastClaimDate).getTime();

        const diffInMilliseconds = currentTime - lastClaimDate;
        const diffInMinutes = diffInMilliseconds / 1000 / 60;

        return diffInMinutes >= 5; 
    }

}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});